import Axios from 'axios'
import { baseUrl, baseUrl2 } from 'lib/config'
import { getToken } from './client'

const NIMBUS_IDENTITY = process.env.REACT_APP_NIMBUS_IDENTITY
const NIMBUS_POS = process.env.REACT_APP_NIMBUS_POS
const NIMBUS_TRANSACTION = process.env.REACT_APP_NIMBUS_TRANSACTION

export const axios = Axios.create({
  baseURL: baseUrl,
  headers: {
    'App-Id': process.env.REACT_APP_APP_ID,
  },
})

export const axios2 = Axios.create({
  baseURL: baseUrl2,
  headers: {
    'App-Id': process.env.REACT_APP_APP_ID,
  },
})
export const nimbusIdentityClient = Axios.create({
  baseURL: NIMBUS_IDENTITY,
})
export const nimbusPosClient = Axios.create({
  baseURL: NIMBUS_POS,
})
export const nimbusTransactionClient = Axios.create({
  baseURL: NIMBUS_TRANSACTION,
})

nimbusIdentityClient.interceptors.request.use((config) => {
  if (config.url.match(`login`)) return
  config.headers.Authorization = ['Bearer', getToken()].join(' ')
  return config
})

nimbusPosClient.interceptors.request.use((config) => {
  if (config.url.match(`login`)) return
  config.headers.Authorization = ['Bearer', getToken()].join(' ')
  return config
})
nimbusTransactionClient.interceptors.request.use((config) => {
  if (config.url.match(`login`)) return
  config.headers.Authorization = ['Bearer', getToken()].join(' ')
  return config
})

axios.interceptors.request.use((config) => {
  if (config.url.match(`login`)) return
  config.headers.Authorization = ['Bearer', getToken()].join(' ')
  return config
})
axios2.interceptors.request.use((config) => {
  if (config.url.match(`login`)) return
  config.headers.Authorization = ['Bearer', getToken()].join(' ')
  return config
})
axios.interceptors.request.use((config) => {
  if (config.data instanceof FormData) {
    // Object.assign(config.headers, config.data.getHeaders())
  }
  return config
})
export const pickErrorKey =
  (key = 'error_message') =>
  (error) => {
    // Check if error.response.data exists, otherwise fallback to error.response
    const errorData = error.response?.data || error.response

    if (errorData) {
      if (!key) {
        return Promise.reject(errorData)
      }
      return Promise.reject(errorData?.[key] ?? errorData)
    } else if (error.request) {
      throw new Error(`Unexpected request error`)
    } else {
      throw new Error(`Client error`)
    }
  }
export const pickResponseKey =
  (key = 'result') =>
  (response) => {
    const data = response.data || response // Fallback to response if response.data does not exist
    if (!key) {
      return data
    }
    return data[key]
  }

export const pickResult = pickResponseKey('result')
export const pickErrorMessage = pickErrorKey('error_message')
