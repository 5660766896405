import React, { useCallback, useMemo } from 'react'
import { fetchTransactionTypes, fetchSearchByOptions } from 'transactions/transactions.api.client'

import { useQuery } from 'react-query'
import capitalize from 'lodash.capitalize'
import snakeCase from 'lodash.snakecase'
import compose from 'ramda/es/compose'
import split from 'ramda/es/split'
import map from 'ramda/es/map'
import join from 'ramda/es/join'

const transformTransactionType = (type) => ({
  value: type.value,
  name: compose(join(' '), map(capitalize), split('_'), snakeCase)(type.name),
})

export function useTransactionTypes(acceptList = []) {
  const { data: _transactionTypes = [] } = useQuery('transaction-config-Types', fetchTransactionTypes, {
      staleTime: Infinity,
  })
  const transactionTypes = React.useMemo(() => {
      return _transactionTypes?.concat({ value: '', name: 'All' })
    }, [_transactionTypes])

  const checkFilter = acceptList.length > 0
  return useMemo(() => {
    if (!checkFilter) {
      return transactionTypes.map(transformTransactionType)
    }
    return transactionTypes.reduce((acc, type) => {
      if (acceptList.includes(type.name)) {
        return acc.concat(transformTransactionType(type))
      }
      return acc
    }, [])
  }, [transactionTypes, acceptList, checkFilter])
}

export function useTransactionUtils() {
  const types = useTransactionTypes()

  const byName = useCallback(
    (name) => {
      return types.find((t) => t.name.toLowerCase() === name.replace(/\s/g, '').toLowerCase())
    },
    [types]
  )

  const byValue = useCallback(
    (value) => {
      return types.find((t) => String(value) === String(t.value))
    },
    [types]
  )

  return useMemo(() => {
    return {
      byName,
      byValue,
      types,
    }
  }, [types, byName, byValue])
}

export function useSearchByOptions() {
const { data: searchByOptions = [] } = useQuery('transactions-config-search-by', fetchSearchByOptions, {
    staleTime: Infinity,
  })
  return searchByOptions
}
